import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

export default function Title({ className, children }) {
  return <S.Title className={className}>{children}</S.Title>;
}

Title.defaultProps = { className: '' };
Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};
