import styled from 'styled-components';

export const Doctors = styled.section`
  padding: 12.8rem 3.2rem 6.4rem 3.2rem;

  @media screen and (min-width: 768px) {
    padding: 12.8rem 6.4rem 6.4rem 6.4rem;
  }
  @media screen and (min-width: 1200px) {
    padding: 12.8rem 12.8rem 6.4rem 12.8rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 12.8rem calc((100vw - 1400px) / 2) 6.4rem
      calc((100vw - 1400px) / 2);
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.dark.hex()};
  font-size: ${({ theme }) => theme.sizes.h1};
  font-weight: ${({ theme }) => theme.weights.strong};
  line-height: ${({ theme }) => theme.lineHeights.title};
  text-align: center;
  margin: 0 0 4rem 0;
`;
