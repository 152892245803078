import React from 'react';
import PropTypes from 'prop-types';

import { LoaderContextProvider } from './loader.context';
import { ToastContextProvider } from './toast.context';

export function AppContextProvider({ children }) {
  return (
    <LoaderContextProvider>
      <ToastContextProvider>{children}</ToastContextProvider>
    </LoaderContextProvider>
  );
}
AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useLoader } from './loader.context';
export { useToast } from './toast.context';
