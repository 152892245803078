import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const INITIAL_STATE = { label: 'Carregando...', status: false };

const LoaderContext = createContext({
  state: INITIAL_STATE,
  setState: () => {},
});

export function LoaderContextProvider({ children }) {
  const [state, setState] = useState(INITIAL_STATE);

  return (
    <LoaderContext.Provider value={{ state, setState }}>
      {children}
    </LoaderContext.Provider>
  );
}
LoaderContextProvider.propTypes = { children: PropTypes.node.isRequired };

/**
 * Get loader context exposed values
 *
 * @typedef {Object} Context
 * @property {function} setState - A way to update context values
 * @property {string} state.label - Background action label
 * @property {boolean} state.status - Represent loader visibility
 *
 * @returns {Context} Context exposed values
 */
export const useLoader = () => useContext(LoaderContext);
