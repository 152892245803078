import styled from 'styled-components';

export const Accordion = styled.ul``;

export const Toggler = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.gradients.primary};
  border: none;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  display: flex;
  justify-content: space-between;
  min-height: 4.8rem;
  outline-color: ${({ theme }) => theme.colors.light.alpha(0.4)};
  padding: 1.6rem 2.4rem;
  width: 100%;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.lightest.hex()};
  font-size: ${({ theme }) => theme.sizes.strong};
  font-weight: ${({ theme }) => theme.weights.strong};
  margin: 0 1rem 0 0;
  line-height: ${({ theme }) => theme.lineHeights.title};
  text-align: left;
`;

export const Icon = styled.span`
  color: ${({ theme }) => theme.colors.lightest.hex()};
  transition: transform 400ms;
  will-change: transform;
  > svg {
    transform: rotate(-90deg);
  }
`;

export const Collapsible = styled.div`
  background-color: ${({ theme }) => theme.colors.lightest.hex()};
  color: ${({ theme }) => theme.colors.dark.hex()};
  height: 0;
  font-size: ${({ theme }) => theme.sizes.p};
  line-height: ${({ theme }) => theme.lineHeights.p};
  overflow: hidden;
  padding: 2.4rem;
  margin: -2.4rem 0;
  transition: margin 200ms, transform 400ms;
  transform: scaleY(0);
  transform-origin: top;
  will-change: height, margin, transform;
`;

export const Item = styled.li`
  &.--active {
    > ${Collapsible} {
      height: 100%;
      margin: 0;
      transform: scaleY(1);
    }

    > ${Toggler} > ${Icon} {
      transform: rotate(180deg);
    }
  }
`;
