import styled from 'styled-components';

import Button from '../Button';

export const Body = styled.div`
  align-items: center;
  display: flex;
  padding: 3.2rem 1.6rem 3.2rem 3.2rem;
`;

export const Close = styled(Button)`
  height: ${({ theme }) => theme.sizes.btn};
  padding: 0.8rem;
  outline-color: ${({ theme }) => theme.colors.lightest.alpha(0.4)};
  margin: 0 0 0 1.6rem;
  width: ${({ theme }) => theme.sizes.btn};

  > svg {
    margin: 0;
  }
`;

export const Message = styled.p`
  display: block;
  color: ${({ theme }) => theme.colors.lightest.hex()};
  font-size: ${({ theme }) => theme.sizes.p};
  line-height: ${({ theme }) => theme.lineHeights.p};
  width: 100%;
`;

export const Toast = styled.div`
  background-color: ${({ theme }) => theme.colors.lightest.hex()};
  bottom: 10px;
  box-shadow: ${({ theme }) => theme.shadows.md};
  left: 50%;
  max-width: 40rem;
  opacity: 0;
  position: fixed;
  transform: translate(-50%);
  visibility: hidden;
  width: 80%;
  z-index: ${({ theme }) => theme.indexes.toast};

  &.--visible {
    opacity: 1;
    visibility: visible;
  }

  &.success {
    > ${Body} {
      background: ${({ theme }) => theme.gradients.secondary};
    }
  }

  &.error {
    > ${Body} {
      background: ${({ theme }) => theme.gradients.primary};
    }
  }
`;
