import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Facebook,
  Instagram,
  Linkedin,
  Whatsapp,
  Youtube,
} from 'styled-icons/boxicons-logos';

import { $scrollTo } from '../../utils';

import Button from '../Button';

import TogglerIcon from './TogglerIcon';

import * as S from './styled';

const ICONS = {
  Facebook: <Facebook size={24} />,
  Instagram: <Instagram size={24} />,
  LinkedIn: <Linkedin size={24} />,
  YouTube: <Youtube size={24} />,
};

function Navbar({ title }) {
  const [active, setActive] = useState(false);
  const [docked, setDocked] = useState(false);
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          homeActions {
            whatsapp
          }
          attendance {
            items {
              title
              display {
                url
              }
            }
          }
          services {
            title
          }
          social {
            items {
              title
              display
              url
            }
          }
        }
      }
      file(relativePath: { eq: "brand.png" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  function handleToggler() {
    setActive((prev) => !prev);
  }

  function dockHandler() {
    if (window.scrollY > 8) return setDocked(true);
    return setDocked(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', dockHandler);

    return () => {
      window.removeEventListener('scroll', dockHandler);
    };
  }, []);

  function onScrollToHandler(ref) {
    setActive(false);
    return $scrollTo.go(ref);
  }

  const [{ url: whatsappLink }] = data.site.siteMetadata.attendance.items.find(
    (each) => each.title === 'WhatsApp',
  ).display;
  return (
    <S.Navbar
      className={`${docked && '--docked'} ${active && '--active'}`}
      id="navbar"
    >
      <S.Toggler
        type="button"
        title="Abrir menu de navegação"
        onClick={handleToggler}
      >
        <TogglerIcon active={active} />
      </S.Toggler>
      {/* <S.Brand
        fixed={data.file.childImageSharp.fixed}
        title={title}
        alt={title}
      /> */}
      <S.NavAction onClick={() => window.open(whatsappLink, '_blank')}>
        <Whatsapp size={24} />
      </S.NavAction>
      <S.Nav className={active && '--visible'}>
        <S.NavLinks>
          <S.NavLink onClick={() => onScrollToHandler('#about')}>
            {title}
          </S.NavLink>
          <S.NavLink onClick={() => onScrollToHandler('#services')}>
            {data.site.siteMetadata.services.title}
          </S.NavLink>
          <S.NavLink onClick={() => onScrollToHandler('#contacts')}>
            CONTATOS
          </S.NavLink>
          <Button
            variant="secondary"
            onClick={() => window.open(whatsappLink, '_blank')}
          >
            <Whatsapp size={24} />
            {/* {data.site.siteMetadata.homeActions.whatsapp} */}
            Contato
          </Button>
        </S.NavLinks>
        {/* <S.Socials>
          {data.site.siteMetadata.social.items.map((each) => (
            <S.Social
              alt={`Ver ${each.display} no ${each.title}`}
              href={each.url}
              key={each.title}
              target="_blank"
              title={`Ver ${each.display} no ${each.title}`}
              rel="noreferrer noopener"
            >
              {ICONS[each.title]}
            </S.Social>
          ))}
        </S.Socials> */}
      </S.Nav>
    </S.Navbar>
  );
}

Navbar.defaultProps = { title: '' };
Navbar.propTypes = { title: PropTypes.string };

export default Navbar;
