import styled from 'styled-components';

import { Base, Item, Link } from '../styled';

export { Content, GroupTitle as ListTitle, Icon, Items } from '../styled';

export const Title = styled(Base)``;

export const Display = styled(Link)``;

export const Social = styled(Item)``;

export const Socials = styled.div``;
