import styled, { css } from 'styled-components';

const SecondaryTheme = css`
  background: ${({ theme }) => theme.gradients.secondary};
`;

const TransparentTheme = css`
  background: transparent;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.lightest.hex()};
`;

export const Button = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.gradients.primary};
  border: none;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  color: ${({ theme }) => theme.colors.lightest.hex()};
  cursor: pointer;
  font-size: ${({ theme }) => theme.sizes.p};
  font-weight: ${({ theme }) => theme.weights.strong};
  display: flex;
  height: ${({ theme }) => theme.sizes.btn};
  justify-content: center;
  padding: 1rem 2rem;
  outline-color: ${({ theme }) => theme.colors.lightest.alpha(0.4)};
  text-transform: uppercase;
  transition: opacity 400ms, transform 400ms;
  width: 100%;
  will-change: opacity, transform;

  > svg {
    margin: 0 1rem 0 0;
  }

  &.--cta {
    height: ${({ theme }) => theme.sizes.cta};
  }

  &.secondary {
    ${SecondaryTheme};
  }

  &.transparent {
    ${TransparentTheme};
  }

  :hover {
    opacity: 0.9;
  }

  :active {
    transform: scale(0.96);
  }
`;
