import styled from 'styled-components';

import { Button, Card as RawCard } from '../../../index';

export const Card = styled(RawCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.2em;

  @media screen and (min-width: 1024px) {
    height: 68rem;
    padding: 3.2rem 6.4rem;
    box-shadow: none;
  }

  @media screen and (min-width: 1200px) {
    height: 64rem;
  }
`;

export const Form = styled.form`
  max-width: 80rem;
  width: 100%;
`;

export const Submit = styled(Button)`
  @media (min-width: 1024px) {
    padding: 1rem 4rem;
    width: fit-content;
  }
`;

export const Warning = styled.p`
  color: ${({ theme }) => theme.colors.dark.alpha(0.48)};
  font-size: ${({ theme }) => theme.sizes.p};
  line-height: ${({ theme }) => theme.lineHeights.p};
`;

export const Footer = styled.div`
  margin: 3.2rem 0 0 0;
  > ${Submit} {
    margin: 0 0 1.6rem 0;
  }
`;
