import { createGlobalStyle } from 'styled-components';

import resets from './resets.styles';

export default createGlobalStyle`
  ${resets}

  body {
    background-color: ${({ theme }) => theme.colors.light.hex()};
  }
`;
