import React from 'react';

import * as S from './styled';

import Form from './Form';
import Map from './Map';

export default function Contacts() {
  return (
    <S.Contacts id="contacts">
      <Map />
      <Form />
    </S.Contacts>
  );
}
