function go(ref, offset = -40) {
  if (!ref) return;

  const navbar = document.querySelector('#navbar');
  const [{ height: navHeight }] = navbar.getClientRects();

  const target = document.querySelector(ref);
  if (!target) return;

  const [{ top }] = target.getClientRects();

  const { scrollY: offsetY } = window;

  window.scrollTo({
    top: top - navHeight - offset + offsetY,
    behavior: 'smooth',
  });
}

export default { go };
