import React from 'react';
import PropTypes from 'prop-types';

import { useLoader } from '../../contexts';

import * as S from './styled';

export default function Loader({ label, status }) {
  const { state } = useLoader();

  return (
    <S.Loader className={(state.status || status) && '--visible'}>
      <S.Background>
        <S.Spinner />
        <S.Label>{label || state.label}</S.Label>
      </S.Background>
    </S.Loader>
  );
}

Loader.defaultProps = { label: 'Carregando...', status: false };
Loader.propTypes = { label: PropTypes.string, status: PropTypes.bool };
