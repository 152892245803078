import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { AppContextProvider } from '../../contexts';
import { GlobalStyle, Theme } from '../../styles';

import Footer from '../Footer';
import Header from '../Header';
import Loader from '../Loader';
import Navbar from '../Navbar';
import SEO from '../SEO';
import Toast from '../Toast';

import * as S from './styled';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <AppContextProvider>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <SEO title={data.site.siteMetadata.title} />

        <Header>
          <Navbar title={data.site.siteMetadata.title} />
        </Header>
        <S.Main>{children}</S.Main>
        <Footer />

        <Loader />
        <Toast />
      </ThemeProvider>
    </AppContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
