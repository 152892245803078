import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

export default function TogglerIcon({ active }) {
  return <S.TogglerIcon className={active && '--active'} />;
}

TogglerIcon.defaultProps = { active: false };
TogglerIcon.propTypes = { active: PropTypes.bool };
