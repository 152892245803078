import styled from 'styled-components';

import { Strong, Light } from '../styled';

export const Name = styled(Strong)``;
export const Registry = styled(Light)``;

export const Doctor = styled.li`
  > ${Name}, > ${Registry} {
    display: block;
    text-align: center;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    > ${Name}, > ${Registry} {
      text-align: left;
    }
  }
`;

export const Doctors = styled.ul`
  margin: 3.2rem 0 0 0;

  @media screen and (min-width: 1200px) {
    margin: 3.2rem 0 0 4rem;
  }

  > ${Doctor}:not(:last-of-type) {
    margin: 0 0 1.6rem 0;
  }
`;
