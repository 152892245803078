import React from 'react';

import { Layout } from '../components';

import About from '../components/Sections/About';
import Contacts from '../components/Sections/Contacts';
import Doctors from '../components/Sections/Doctors';
import Home from '../components/Sections/Home';
import Services from '../components/Sections/Services';

export default function HomePage() {
  return (
    <Layout>
      <Home />
      <About />
      <Services />
      <Doctors />
      <Contacts />
    </Layout>
  );
}
