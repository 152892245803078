import React from 'react';
import * as S from './styled.js';
import DoctorGrid from './DoctorGrid';

const Doctors = props => {
  return (
    <S.Doctors>
      {/* <S.Title>Conheça nossos Sócios</S.Title> */}
      <DoctorGrid />
    </S.Doctors>
  )
}

export default Doctors;