import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const INITIAL_STATE = {
  content: '',
  theme: '',
  status: false,
};
const ToastContext = createContext({
  config: INITIAL_STATE,
  setConfig: () => {},
});

export function ToastContextProvider({ children }) {
  const [config, setConfig] = useState(INITIAL_STATE);

  return (
    <ToastContext.Provider value={{ config, setConfig }}>
      {children}
    </ToastContext.Provider>
  );
}
ToastContextProvider.propTypes = { children: PropTypes.node.isRequired };

/**
 * Get toast context exposed values
 *
 * @returns {{config: {theme: string, content: string, status: boolean}, setConfig: function}}
 */
export const useToast = () => useContext(ToastContext);
