import axios from 'axios';

const PROPS_LABELS = {
  name: 'Nome',
  email: 'E-mail',
  phone: 'Telefone',
  subject: 'Assunto',
};

const MASKS = {
  phone: (value) => {
    if (!value) return value;

    return value
      .replace(/\D/g, '')
      .replace(/(\d{11})(\d)/, '$1')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4,5})(\d)/, '$1-$2');
  },
};

function send(url) {
  return async (data) => {
    try {
      const res = await axios({ data, url, method: 'POST' });
      return res.data.ok;
    } catch (ex) {
      return Promise.reject(ex);
    }
  };
}

function formInputHandler(setter) {
  return ({ target }) => {
    const { name, value: rawValue } = target;

    const label = PROPS_LABELS[name] ? PROPS_LABELS[name] : name;
    const value = MASKS[name] ? MASKS[name](rawValue) : rawValue;

    return setter((prev) => ({ ...prev, [label]: value }));
  };
}

export default { send, formInputHandler };
