import chroma from 'chroma-js';

const colors = {
  primary: chroma('#666666'),
  primaryDark: chroma('#212121'),

  secondary: chroma('#1e522e'),
  secondaryDark: chroma('#112d19'),

  dark: chroma('#1a1a1a'),
  darkest: chroma('#212121'),

  light: chroma('#F5F5F5'),
  lightest: chroma('#FFFFFF'),
};

export default {
  colors,
  gradients: {
    primary: `linear-gradient(
      to right bottom,
      ${colors.primary.hex()},
      ${colors.primaryDark.hex()}
    )`,
    secondary: `linear-gradient(
      to right bottom,
      ${colors.secondary.hex()},
      ${colors.secondaryDark.hex()}
    )`,
    dark: (opacity = 1) => `linear-gradient(
      to right bottom,
      ${colors.dark.alpha(opacity)},
      ${colors.darkest.alpha(opacity)}
    )`,
  },
  shadows: {
    md: `2px 8px 16px 0 ${colors.darkest.alpha(0.32)}`,
    sm: `1px 4px 8px 0 ${colors.darkest.alpha(0.24)}`,
    soft: `0 4px 2px 0 ${colors.darkest.alpha(0.16)}`,
  },
  sizes: {
    h1: '32px',
    h2: '24px',
    strong: '18px',
    p: '16px',
    btn: '48px',
    cta: '64px',
  },
  weights: {
    light: '300',
    normal: '400',
    strong: '500',
    bold: '700',
  },
  lineHeights: {
    title: '136%',
    p: '160%',
  },
  indexes: {
    loader: 999,
    navbar: 99,
    navbarOverlay: 9,
    toast: 99,
  },
};
