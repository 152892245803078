import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Button({ children, className, cta, type, variant, onClick }) {
  return (
    <S.Button
      className={`${variant} ${className} ${cta && '--cta'}`}
      type={type}
      onClick={onClick}
    >
      {children}
    </S.Button>
  );
}

Button.defaultProps = {
  className: '',
  cta: false,
  type: 'button',
  variant: '',
  onClick: () => {},
};
Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cta: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['transparent', 'secondary', '']),
  onClick: PropTypes.func,
};

export default Button;
