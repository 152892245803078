import React from 'react';
import PropTypes from 'prop-types';
import { Alarm, Warning } from 'styled-icons/material';

import * as S from './styled';

const ICONS = {
  'Horários de Atendimento': <Alarm />,
};

const OTHER_TYPE = {
  title: PropTypes.string.isRequired,
  display: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function Other({ title, display }) {
  return (
    <S.Other>
      <S.Icon>{ICONS[title] ? ICONS[title] : <Warning />}</S.Icon>
      <S.Content>
        <S.Title>{title}</S.Title>
        {display.map((each) => (
          <S.Display key={each}>{each}</S.Display>
        ))}
      </S.Content>
    </S.Other>
  );
}
Other.propTypes = OTHER_TYPE;

export default function Others({ title, items }) {
  return (
    <S.Others>
      <S.ListTitle>{title}</S.ListTitle>
      <S.Items>
        {items.map((each, index) => (
          <Other key={index} {...each} />
        ))}
      </S.Items>
    </S.Others>
  );
}
Others.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(OTHER_TYPE)).isRequired,
};
