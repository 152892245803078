import styled from 'styled-components';

export const Contacts = styled.section`
  margin: 0;
  padding: 0 3.2rem;

  @media screen and (min-width: 768px) {
    padding: 0 6.4rem;
  }

  @media screen and (min-width: 1024px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 12.8rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 12.8rem calc((100vw - 1400px) / 2) 6.4rem
      calc((100vw - 1400px) / 2);
  }
`;
