import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import * as S from './styled';

export default function FeaturedItem({ description, icon, title }) {
  return (
    <S.FeaturedItem>
      {/* <S.IconContainer>{icon}</S.IconContainer> */}
      {/* <S.Title>{parse(title)}</S.Title> */}
      <S.Description>{parse(description)}</S.Description>
    </S.FeaturedItem>
  );
}

FeaturedItem.defaultProps = { icon: null };
FeaturedItem.propTypes = {
  description: PropTypes.string.isRequired,
  // icon: PropTypes.node,
  // title: PropTypes.string.isRequired,
};
