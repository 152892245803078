import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

export default function Card({ children, className }) {
  return <S.Card className={className}>{children}</S.Card>;
}

Card.defaultProps = { className: '' };
Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
