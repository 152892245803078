import React from 'react';
import parse from 'html-react-parser';
import YouTube from 'react-youtube';
import GatsbyImage from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import FeaturedItem from './FeaturedItem';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as S from './styled';

export default function About() {

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "about-content-photo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile {
        nodes {
          childImageSharp {
            fixed {
              originalName
              ...GatsbyImageSharpFixed
            }
            fluid(quality: 90) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      site {
        siteMetadata {
          about {
            title
            items {
              description
              image
              title
            }
            photos
            paragraph {
              content
              title
            }
            videoId
          }
        }
      }
    }
  `);

  function getIcon(name) {
    const image = data.allFile.nodes.find(({ childImageSharp: item }) => {
      return item.fixed.originalName === name;
    });
    return image ? image.childImageSharp.fixed : null;
  }

  const gallery = (urlArray) => {
    let photoGallery = [];
    urlArray.forEach(element => {
      photoGallery.push(data.allFile.nodes.find(({ childImageSharp: item }) => {
        return item.fluid.originalName === element;
      }));
    });
    return photoGallery;
  }

  return (
    <S.About id="about">
      <S.Title>{data.site.siteMetadata.about.title}</S.Title>
      <S.ItemsGrid>
        {data.site.siteMetadata.about.items.map(
          ({ title, description, image }) => (
            <FeaturedItem
              description={description}
            />
          ),
        )}
      </S.ItemsGrid>
      <S.ParagraphGrid>
        <S.Title>{data.site.siteMetadata.about.paragraph.title}</S.Title>
        <S.GalleryCarousel showThumbs={false} infiniteLoop={true} autoPlay={true} showStatus={false}>
          {gallery(data.site.siteMetadata.about.photos).map((item, index) => (
            <GatsbyImage key={index} fluid={item.childImageSharp.fluid} />
          ))}
        </S.GalleryCarousel>
        {/* <S.VideoWrapper>
          <YouTube videoId={data.site.siteMetadata.about.videoId} />
        </S.VideoWrapper> */}
        <S.GridContent>
          <S.Title>{data.site.siteMetadata.about.paragraph.title}</S.Title>
          <S.Paragraph>
            {parse(data.site.siteMetadata.about.paragraph.content)}
          </S.Paragraph>
        </S.GridContent>
      </S.ParagraphGrid>
    </S.About>
  );
}
