import styled from 'styled-components';

import { Base, Item, Light, Link, Strong } from '../styled';

export { Content, GroupTitle as ListTitle, Icon, Items } from '../styled';

export const Channel = styled(Item)``;

export const Display = styled(Strong)``;

export const DisplayLink = styled(Link)``;

export const Obs = styled(Light)``;

export const Title = styled(Base)``;

export const Channels = styled.div``;
