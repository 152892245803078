import styled from 'styled-components';

export const Background = styled.div`
  background: ${({ theme }) => theme.gradients.dark(0.8)};
  box-shadow: ${({ theme }) => theme.shadows.md};
  flex-direction: column;
  padding: 4rem;
`;

export const Spinner = styled.span`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 880ms linear infinite;
  background-color: transparent;
  border: 1.6rem solid ${({ theme }) => theme.colors.lightest.alpha(0.16)};
  border-bottom-color: ${({ theme }) => theme.colors.primary.hex()};
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.lightest.hex()};
  font-size: ${({ theme }) => theme.sizes.p};
  line-height: ${({ theme }) => theme.lineHeights.p};
  margin: 2rem 0 0 0;
  text-align: center;
`;

export const Loader = styled.div`
  background: ${({ theme }) => theme.gradients.dark(0.96)};
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 400ms, visibility 400ms;
  visibility: hidden;
  z-index: ${({ theme }) => theme.indexes.loader};
  width: 100%;
  will-change: opacity, visibility;

  &.--visible {
    opacity: 1;
    visibility: visible;
  }

  &,
  > ${Background} {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;
