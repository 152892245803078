import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';

import Title from '../Title';

export const Brand = styled(GatsbyImage)`
  height: auto;
  margin: 0 auto;
  max-height: 10rem;
  max-width: 24rem;
  width: 100%;

  @media (min-width: 1200px) {
    margin: -3rem 0 0 4rem;
  }

`;

export const GroupTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.lightest.hex()};
  margin: 4rem 0 2.4rem 0;

  @media (min-width: 1200px) {
    margin: 0 0 2.4rem 0;
  }
`;

const textBase = css`
  color: ${({ theme }) => theme.colors.lightest.hex()};
  font-size: ${({ theme }) => theme.sizes.p};
  line-height: ${({ theme }) => theme.lineHeights.p};
`;

export const Base = styled.p`
  ${textBase};
`;

export const Icon = styled.span`
  color: ${({ theme }) => theme.colors.lightest.hex()};
  flex-shrink: 0;
  height: 3.2rem;
  margin: 0 1.6rem 0 0;
  width: 3.2rem;
`;

export const Strong = styled(Base)`
  font-weight: ${({ theme }) => theme.weights.strong};
`;

export const Light = styled(Base)`
  font-weight: ${({ theme }) => theme.weights.light};
`;

export const Link = styled.a`
  ${textBase};
  display: block;
  font-weight: ${({ theme }) => theme.weights.strong};
  transition: color 400ms;
  will-change: color;
  width: 100%;

  :hover {
    color: ${({ theme }) => theme.colors.light.hex()};
  }
`;

export const Content = styled.span``;

export const Item = styled.li`
  align-items: center;
  display: flex;
`;

export const Items = styled.ul`
  > li:not(:last-of-type) {
    margin: 0 0 1.6rem 0;
  }

  @media (min-width: 1200px) {
    margin: 0;
  }
`;

export const Footer = styled.footer`
  padding: 12.8rem 3.2rem 6.4rem 3.2rem;
  position: relative;
  overflow-y: visible;

  &::before {
    background: ${({ theme }) => theme.gradients.primary};
    content: '';
    display: block;
    height: calc(100% + 128px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transform: translate(0, -128px);
    z-index: -1;
  }

  @media screen and (min-width: 768px) {
    padding: 12.8rem 6.4rem 6.4rem 6.4rem;
  }

  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.6rem;
    padding: 12.8rem 12.8em 6.4rem 12.8em;
  }

  @media screen and (min-width: 1400px) {
    column-gap: 4.8rem;
    padding: 12.8rem calc((100vw - 1400px) / 2) 6.4rem
      calc((100vw - 1400px) / 2);
  }
`;
