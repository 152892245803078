import styled from 'styled-components';

import { Base, Item, Strong } from '../styled';

export { Content, GroupTitle as ListTitle, Icon, Items } from '../styled';

export const Display = styled(Strong)``;

export const Title = styled(Base)``;

export const Other = styled(Item)``;

export const Others = styled.div``;
