import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

export default function TextArea({
  className,
  label,
  name,
  type,
  value,
  onInput,
  ...rest
}) {
  return (
    <S.Input className={className}>
      <S.Label htmlFor={name}>{label}</S.Label>
      <S.TextField
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onInput}
        {...rest}
      />
    </S.Input>
  );
}

TextArea.defaultProps = {
  className: '',
  type: 'text',
  value: '',
  onInput: () => {},
};

TextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onInput: PropTypes.func,
};