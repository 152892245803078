import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';


export const DoctorGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`

export const DoctorItem = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.lightest.hex()};
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 2rem 1rem;
  position: relative;
  width: 30rem;

  &::before {
    background: ${({ theme }) => theme.colors.primary};
    box-shadow:  0 6px 25px -4px rgba(0,0,0,0.25); 
    content: ' ';
    height: 50%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const DoctorName = styled.h6`
  font-family: 'Fira Sans','Roboto',Helvetica,Arial,sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 1rem auto;
  text-align: center;
  position: relative;
  z-index: 3;
`

export const DoctorRegistry = styled.p`
  font-family: 'Fira Sans','Roboto',Helvetica,Arial,sans-serif;
  font-size: 1.4rem;
  text-align: center;
  position: relative;
  z-index: 3;  
`

export const DoctorPhoto = styled(GatsbyImage)`
  margin: 1.5rem 0;
`