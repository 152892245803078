import React from 'react';
import PropTypes from 'prop-types';
import { Close } from 'styled-icons/material';

import { useToast } from '../../contexts';

import * as S from './styled';

export default function Toast({ message, theme, status, onClose }) {
  const { config, setConfig } = useToast();

  function onCloseHandler() {
    setConfig((prev) => ({ ...prev, status: false }));
    return onClose();
  }

  return (
    <S.Toast
      className={`${config.theme || theme} ${
        (config.status || status) && '--visible'
      }`}
    >
      <S.Body>
        <S.Message>{message || config.message}</S.Message>
        <S.Close variant="transparent" onClick={onCloseHandler}>
          <Close size={24} />
        </S.Close>
      </S.Body>
    </S.Toast>
  );
}

Toast.defaultProps = {
  message: '',
  theme: 'success',
  status: false,
  onClose: () => {},
};
Toast.propTypes = {
  message: PropTypes.string,
  theme: PropTypes.oneOf(['success', 'error']),
  status: PropTypes.bool,
  onClose: PropTypes.func,
};
